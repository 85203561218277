import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    addWorkspaceState: { workspace: null, isloading: "", errors: null },
    fetchWorkspacePermissionsState: {
        permissions: null,
        isloading: "",
        errors: null,
    },
    updateWorkspaceState: { workspace: null, isloading: "", errors: null },
    fetchWorkspacesState: { workspaces: [], isloading: "", errors: null },
    getWorkspaceState: { workspace: null, isloading: "", errors: null },
    logintoWorkspaceState: { active_workspace: null },
    acceptWorkspaceInvitationState: { workspace_id: null },
    fetchClinicsState: {
        clinics: [],
        isloading: "",
        errors: null,
    },
    fetchClinicState: {
        clinic: null,
        isloading: "",
        errors: null,
    },
    deleteWorkspaceState: {
        isloading: "",
        errors: null,
    },
    filters: {
        offset: 0,
        limit: 10,
    },
};

export const workspaceSlice = createSlice({
    name: "workspace",
    initialState,
    reducers: {
        addWorkspace: (state) => {
            state.addWorkspaceState.isloading = "processing";
            state.addWorkspaceState.errors = null;
        },
        addWorkspaceSuccess: (state, action) => {
            state.addWorkspaceState.isloading = "success";
            state.addWorkspaceState.workspace = action?.payload;
        },
        addWorkspaceFailed: (state, action) => {
            state.addWorkspaceState.isloading = "failed";
            state.addWorkspaceState.errors = action?.payload;
        },
        updateWorkspace: (state) => {
            state.updateWorkspaceState.isloading = "processing";
            state.updateWorkspaceState.errors = null;
        },
        updateWorkspaceSuccess: (state, action) => {
            state.updateWorkspaceState.isloading = "success";
            state.updateWorkspaceState.workspace = action?.payload;
        },
        updateWorkspaceFailed: (state, action) => {
            state.updateWorkspaceState.isloading = "failed";
            state.updateWorkspaceState.errors = action?.payload;
        },
        fetchWorkspacePermissions: (state) => {
            state.fetchWorkspacePermissionsState.isloading = "processing";
            state.fetchWorkspacePermissionsState.permissions = null;
            state.fetchWorkspacePermissionsState.errors = null;
        },
        fetchWorkspacePermissionsSuccess: (state, action) => {
            state.fetchWorkspacePermissionsState.isloading = "success";
            state.fetchWorkspacePermissionsState.permissions = action?.payload;
        },
        fetchWorkspacePermissionsFailed: (state, action) => {
            state.fetchWorkspacePermissionsState.isloading = "failed";
            state.fetchWorkspacePermissionsState.errors = action?.payload;
        },
        fetchWorkspaces: (state) => {
            state.fetchWorkspacesState.isloading = "processing";
            state.fetchWorkspacesState.errors = null;
        },
        fetchWorkspacesSuccess: (state, action) => {
            state.fetchWorkspacesState.isloading = "success";
            state.fetchWorkspacesState.workspaces = action?.payload;
        },
        fetchWorkspacesFailed: (state, action) => {
            state.fetchWorkspacesState.isloading = "failed";
            state.fetchWorkspacesState.errors = action?.payload;
        },
        getWorkspace: (state) => {
            state.getWorkspaceState.isloading = "processing";
            state.getWorkspaceState.errors = null;
        },
        getWorkspaceSuccess: (state, action) => {
            state.getWorkspaceState.isloading = "success";
            state.getWorkspaceState.workspace = action?.payload;
        },
        getWorkspaceFailed: (state, action) => {
            state.getWorkspaceState.isloading = "failed";
            state.getWorkspaceState.errors = action?.payload;
        },
        getWorkspaceType: (state) => {
            state.getWorkspaceTypeState.isloading = "processing";
            state.getWorkspaceTypeState.errors = null;
        },
        getWorkspaceTypeSuccess: (state, action) => {
            state.getWorkspaceTypeState.isloading = "success";
            state.getWorkspaceTypeState.workspaceType = action?.payload;
        },
        getWorkspaceTypeFailed: (state, action) => {
            state.getWorkspaceTypeState.isloading = "failed";
            state.getWorkspaceTypeState.errors = action?.payload;
        },
        deleteWorkspace: (state) => {
            state.deleteWorkspaceState.isloading = "processing";
            state.deleteWorkspaceState.errors = null;
        },
        deleteWorkspaceSuccess: (state) => {
            state.deleteWorkspaceState.isloading = "success";
        },
        deleteWorkspaceFailed: (state, action) => {
            state.deleteWorkspaceState.isloading = "failed";
            state.deleteWorkspaceState.errors = action?.payload;
        },
        fetchClinics: (state) => {
            state.fetchClinicsState.isloading = "processing";
            state.fetchClinicsState.errors = null;
        },
        fetchClinicsSuccess: (state, action) => {
            state.fetchClinicsState.isloading = "success";
            state.fetchClinicsState.clinics = action?.payload;
        },
        fetchClinicsFailed: (state, action) => {
            state.fetchClinicsState.isloading = "failed";
            state.fetchClinicsState.errors = action?.payload;
        },
        fetchClinic: (state) => {
            state.fetchClinicState.isloading = "processing";
            state.fetchClinicState.errors = null;
        },
        fetchClinicSuccess: (state, action) => {
            state.fetchClinicState.isloading = "success";
            state.fetchClinicState.clinic = action?.payload;
        },
        fetchClinicFailed: (state, action) => {
            state.fetchClinicState.isloading = "failed";
            state.fetchClinicState.errors = action?.payload;
        },
        logintoWorkspace: (state, action) => {
            state.logintoWorkspaceState.active_workspace = action?.payload;
        },
        acceptWorkspaceInvitation: (state, action) => {
            state.acceptWorkspaceInvitationState.workspace_id = action?.payload;
        },
        resetWorkspace: (state) => {
            state.getWorkspaceState.isloading = "";
            state.getWorkspaceState.workspace = null;
            state.getWorkspaceState.errors = null;
        },
        resetUpdateWorkspaceState: (state) => {
            state.updateWorkspaceState.isloading = "";
            state.updateWorkspaceState.workspace = null;
            state.updateWorkspaceState.errors = null;
        },
        resetCreateWorkspaceState: (state) => {
            state.addWorkspaceState.isloading = "";
            state.addWorkspaceState.workspace = null;
            state.addWorkspaceState.errors = null;
        },
        resetFetchClinicsWorkspace: (state) => {
            state.fetchClinicsState.isloading = "";
            state.fetchClinicsState.clinics = [];
            state.fetchClinicsState.errors = null;
        },
    },
});

export const {
    addWorkspace,
    addWorkspaceSuccess,
    addWorkspaceFailed,
    updateWorkspace,
    updateWorkspaceSuccess,
    updateWorkspaceFailed,
    fetchWorkspacePermissions,
    fetchWorkspacePermissionsSuccess,
    fetchWorkspacePermissionsFailed,
    fetchWorkspaces,
    fetchWorkspacesSuccess,
    fetchWorkspacesFailed,
    getWorkspace,
    getWorkspaceSuccess,
    getWorkspaceFailed,
    getWorkspaceType,
    getWorkspaceTypeSuccess,
    getWorkspaceTypeFailed,
    deleteWorkspace,
    deleteWorkspaceSuccess,
    deleteWorkspaceFailed,
    fetchClinics,
    fetchClinicsSuccess,
    fetchClinicsFailed,
    fetchClinic,
    fetchClinicSuccess,
    fetchClinicFailed,
    logintoWorkspace,
    acceptWorkspaceInvitation,
    resetWorkspace,
    resetCreateWorkspaceState,
    resetUpdateWorkspaceState,
    resetFetchClinicsWorkspace,
} = workspaceSlice.actions;

export default workspaceSlice.reducer;
