import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    addMembershipState: { membership: null, isloading: "", errors: null },
    updateMembershipState: { membership: null, isloading: "", errors: null },
    fetchMembersState: { members: [], isloading: "", errors: null },
    searchPatientState: { patient: null, isloading: "", errors: null },
    attachPatientState: { patient: null, isloading: "", errors: null },
    removePatientState: { patient: null, isloading: "", errors: null },
};

export const patientsSlice = createSlice({
    name: "patient",
    initialState,
    reducers: {
        addMembership: (state) => {
            state.addMembershipState.isloading = "processing";
            state.addMembershipState.errors = null;
        },
        addMembershipSuccess: (state, action) => {
            state.addMembershipState.isloading = "success";
            state.addMembershipState.membership = action?.payload;
        },
        addMembershipFailed: (state, action) => {
            state.addMembershipState.isloading = "failed";
            state.addMembershipState.errors = action?.payload;
        },
        updateMembership: (state) => {
            state.updateMembershipState.isloading = "processing";
            state.updateMembershipState.errors = null;
        },
        updateMembershipSuccess: (state, action) => {
            state.updateMembershipState.isloading = "success";
            state.updateMembershipState.membership = action?.payload;
        },
        updateMembershipFailed: (state, action) => {
            state.updateMembershipState.isloading = "failed";
            state.updateMembershipState.errors = action?.payload;
        },
        fetchMembers: (state) => {
            state.fetchMembersState.isloading = "processing";
            state.fetchMembersState.members = null;
            state.fetchMembersState.errors = null;
        },
        fetchMembersSuccess: (state, action) => {
            state.fetchMembersState.isloading = "success";
            state.fetchMembersState.members = action?.payload;
        },
        fetchMembersFailed: (state, action) => {
            state.fetchMembersState.isloading = "failed";
            state.fetchMembersState.errors = action?.payload;
        },
        searchPatient: (state) => {
            state.searchPatientState.isloading = "processing";
            state.searchPatientState.patient = null;
            state.searchPatientState.errors = null;
        },
        searchPatientSuccess: (state, action) => {
            state.searchPatientState.isloading = "success";
            state.searchPatientState.patient = action?.payload;
        },
        searchPatientFailed: (state, action) => {
            state.searchPatientState.isloading = "failed";
            state.searchPatientState.errors = action?.payload;
        },
        attachPatient: (state) => {
            state.attachPatientState.isloading = "processing";
            state.attachPatientState.errors = null;
        },
        attachPatientSuccess: (state) => {
            state.attachPatientState.isloading = "success";
        },
        attachPatientFailed: (state, action) => {
            state.attachPatientState.isloading = "failed";
            state.attachPatientState.errors = action?.payload;
        },
        removePatient: (state) => {
            state.removePatientState.isloading = "processing";
            state.removePatientState.errors = null;
        },
        removePatientSuccess: (state) => {
            state.removePatientState.isloading = "success";
        },
        removePatientFailed: (state, action) => {
            state.removePatientState.isloading = "failed";
            state.removePatientState.errors = action?.payload;
        },
        resetMembership: (state) => {
            state.fetchMembersState.isloading = "";
            state.fetchMembersState.members = null;
            state.fetchMembersState.errors = null;
        },
        resetAddMembership: (state) => {
            state.addMembershipState.isloading = "";
            state.addMembershipState.errors = null;
        },
        resetUpdateMembership: (state) => {
            state.updateMembershipState.isloading = "";
            state.updateMembershipState.membership = null;
            state.updateMembershipState.errors = null;
        },
        resetAttachPatientState: (state) => {
            state.attachPatientState.isloading = "";
            state.attachPatientState.patient = null;
            state.attachPatientState.errors = null;
        },
        resetRemovePatientState: (state) => {
            state.removePatientState.isloading = "";
            state.removePatientState.patient = null;
            state.removePatientState.errors = null;
        },
    },
});

export const {
    addMembership,
    addMembershipSuccess,
    addMembershipFailed,
    updateMembership,
    updateMembershipSuccess,
    updateMembershipFailed,
    fetchMembers,
    fetchMembersSuccess,
    fetchMembersFailed,
    searchPatient,
    searchPatientSuccess,
    searchPatientFailed,
    attachPatient,
    attachPatientSuccess,
    attachPatientFailed,
    removePatient,
    removePatientSuccess,
    removePatientFailed,
    resetMembership,
    resetAddMembership,
    resetUpdateMembership,
    resetAttachPatientState,
    resetRemovePatientState,
} = patientsSlice.actions;

export default patientsSlice.reducer;
