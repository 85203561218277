import { call, put, takeLeading } from "redux-saga/effects";
import { extractErrors, processResponseObject } from "../../../util/helpers";
import {
    login as loginApi,
    signup as signupApi,
    logout as logoutApi,
    fetchUser as fetchUserApi,
} from "./api";
import {
    loginRequest,
    loginSuccess,
    loginFailed,
    signUpRequest,
    signUpSuccess,
    signUpFailed,
    logoutRequest,
    fetchUser,
    fetchUserSuccess,
    fetchUserFailed,
} from "./slice";

export function* getUser() {
    try {
        const response = yield call(fetchUserApi);
        const resp = response && response.data;

        yield put(fetchUserSuccess(resp));
    } catch (e) {
        const _message =
            extractErrors(e.response?.data) ||
            processResponseObject(e.response?.data);
        const message = _message || "Failed to fetch user";
        yield put(fetchUserFailed(message));
    }
}

export function* signin(actions) {
    try {
        yield call(loginApi, actions?.payload);

        yield put(loginSuccess());
    } catch (e) {
        const _message =
            extractErrors(e.response?.data) ||
            processResponseObject(e.response?.data);
        const message = _message || "Failed to signin user";
        yield put(loginFailed(message));
    }
}

export function* signup(actions) {
    try {
        yield call(signupApi, actions?.payload);

        yield put(signUpSuccess());
    } catch (e) {
        const _message =
            extractErrors(e.response?.data) ||
            processResponseObject(e.response?.data);
        const message = _message || "Failed to signup user";
        yield put(signUpFailed(message));
    }
}

export function* signout() {
    try {
        yield call(logoutApi);
        window.location.replace("/");
    } catch (e) {
        console.log("Error: ", e);
        window.location.replace("/");
    }
}

export function* watchLogin() {
    yield takeLeading(loginRequest.type, signin);
}

export function* watchSignUp() {
    yield takeLeading(signUpRequest.type, signup);
}

export function* watchLogout() {
    yield takeLeading(logoutRequest.type, signout);
}

export function* watchFetchUser() {
    yield takeLeading(fetchUser.type, getUser);
}
