import { axiosInstance } from "./axiosDefaults";

export const apiRequest = (method, url, data = {}) => {
    const response = axiosInstance()({
        method,
        url,
        data,
    });

    return response;
};
