import React, { useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { CustomListItem } from "./ListItem";

import LogoIcon from "../../assets/logo.svg";
import InvitationIcon from "../../assets/invitation.svg";
import ManageHomeIcon from "../../assets/manage_home.svg";
import ManagePatientIcon from "../../assets/manage_patient.svg";
import MemberIcon from "../../assets/member.svg";
import { Stack } from "@mui/material";

const drawerWidth = 200;

const useStyles = makeStyles(() => ({
    icon: {
        width: "12px",
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
        },
        zIndex: 1300,
    },
    drawerPaperProps: {
        overflow: "auto",
        backgroundColor: "#1A3E89",
        color: "#fff",
        marginTop: "10px",
    },
    logoContainer: {
        marginTop: "20px",
    },
}));

export const ImgIcon = ({ src, width = "40px" }) => (
    <img src={src} style={{ width: width, marginRight: 0 }} />
);

const manageMenus = [
    {
        text: "Manage Locations",
        icon: <ImgIcon src={ManageHomeIcon} />,
        path: "/care-home-workspaces",
        menuId: 0,
        permission: { action: "view", view: "members" },
    },
    {
        text: "Manage Patients",
        icon: <ImgIcon src={ManagePatientIcon} />,
        path: "/patients",
        menuId: 1,
        permission: { action: "view", view: "members" },
    },
    {
        text: "Manage invitations",
        icon: <ImgIcon src={InvitationIcon} />,
        path: "/invitations",
        menuId: 2,
        permission: { action: "view", view: "invitations" },
    },
    {
        text: "Manage members",
        icon: <ImgIcon src={MemberIcon} />,
        path: "/team_members",
        menuId: 3,
        permission: { action: "view", view: "members" },
    },
    {
        text: "Settings",
        icon: <ImgIcon src={MemberIcon} />,
        path: "/settings",
        menuId: 4,
        permission: { action: "view", view: "members" },
    },
];

const DEFAULT_MENU_ID = 0;

export const SideBar = () => {
    const history = useHistory();
    const classes = useStyles();

    const [selectedMenuId, selectMenu] = useState(DEFAULT_MENU_ID);

    return (
        <Drawer
            variant="permanent"
            className={classes.drawer}
            PaperProps={{
                sx: {
                    backgroundColor: "#1A3E89",
                },
            }}
        >
            <Toolbar>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ width: "100%" }}
                >
                    <Box component="div" className={classes?.logoContainer}>
                        <ImgIcon src={LogoIcon} width="130px" />
                    </Box>
                </Stack>
            </Toolbar>
            <Box className={classes.drawerPaperProps}>
                <List>
                    {manageMenus.map((item) => (
                        <CustomListItem
                            key={item?.text}
                            item={item}
                            selectedMenuId={selectedMenuId}
                            listItemAction={() => {
                                if (item?.path === "/help")
                                    window?.open(
                                        "http://carecheck.com",
                                        "_blank",
                                    );

                                selectMenu(item?.menuId);

                                history.push(item?.path);
                            }}
                        />
                    ))}
                </List>
            </Box>
        </Drawer>
    );
};
