import { defineAbility } from "@casl/ability";

const ability = defineAbility((can, cannot) => {
    can("view", "workspaces");
    can("create", "workspace");

    cannot("delete", "User");
});

export default ability;
