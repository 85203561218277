import { call, put, takeLatest, takeLeading } from "redux-saga/effects";
import { extractErrors, processResponseObject } from "../../../util/helpers";
import {
    fetchMembers as fetchMembersApi,
    updateMembership as updateMembershipApi,
} from "./api";
import {
    updateMembership,
    updateMembershipSuccess,
    updateMembershipFailed,
    fetchMembers,
    fetchMembersSuccess,
    fetchMembersFailed,
} from "./slice";

export function* editMembership(actions) {
    try {
        const { id, workspace_id, data } = actions.payload;

        const response = yield call(
            updateMembershipApi,
            id,
            workspace_id,
            data,
        );
        const resp = response && response.data;

        yield put(updateMembershipSuccess(resp));
    } catch (e) {
        const _message =
            extractErrors(e.response?.data) ||
            processResponseObject(e.response?.data);
        const message = _message || "Failed to update membership";
        yield put(updateMembershipFailed(message));
    }
}

export function* getMembers(actions) {
    try {
        const slug = actions?.payload;
        const response = yield call(fetchMembersApi, slug);
        const resp = response && response.data;
        yield put(fetchMembersSuccess(resp));
    } catch (error) {
        const _message =
            extractErrors(e.response?.data) ||
            processResponseObject(e.response?.data);
        const message = _message || "Failed to fetch members";
        yield put(fetchMembersFailed(message));
    }
}

export function* watchUpdateMembership() {
    yield takeLeading(updateMembership.type, editMembership);
}

export function* watchFetchMembers() {
    yield takeLatest(fetchMembers.type, getMembers);
}
