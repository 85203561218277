import { SearchOutlined } from "@mui/icons-material";
import { InputAdornment, TextField } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";

const SearchInput = ({ handleSubmit }) => {
    const [value, setValue] = useState("");

    return (
        <TextField
            name="search_term"
            sx={{
                width: "400px",
                padding: "10.5px 14px",
                marginLeft: "206px",
            }}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onKeyDown={(e) => {
                console.log(`Pressed keyCode ${e.keyCode} - ${e.key}`);
                if (e.key === "Enter") {
                    e.preventDefault();
                    if (value) handleSubmit(value);
                }
            }}
            inputProps={{
                style: { padding: 8 },
            }}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchOutlined style={{ fontSize: "16px" }} />
                    </InputAdornment>
                ),
                style: { fontSize: "12px", padding: 0, paddingLeft: 10 },
                placeholder: "Search by Access Number",
            }}
        />
    );
};

SearchInput.propTypes = {};

export default SearchInput;
