import { apiRequest } from "../../api/apiRequest";
import { WORKSPACE_ENDPOINT } from "../../api/urls";

export const uploadFile = (workspace_id, data, uploadProgress) => {
    const formData = new FormData();
    formData.append("file_url", data);

    return apiRequest(
        "POST",
        `${WORKSPACE_ENDPOINT}${workspace_id}/upload/file/`,
        formData,
        "json",
        { "Content-Type": "multipart/form-data" },
        uploadProgress,
    );
};

export const fetchWorkspaceFiles = (
    workspace_id,
    searchTerm = "",
    status = "",
) => {
    return apiRequest(
        "GET",
        `${WORKSPACE_ENDPOINT}${workspace_id}/patient/files/?searchTerm=${searchTerm}&&status=${status}`,
    );
};
