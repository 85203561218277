import React from "react";
import PropTypes from "prop-types";
import { makeStyles, Grid } from "@material-ui/core";
import { Divider, Stack, TextField, Box, Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: "10px",
    },
    row: {
        marginBottom: "20px !important",
    },
    fieldContainer: {
        padding: "1rem !important",
        background: "#efeff4",
        borderRadius: "5px",
        border: "1px solid #9999d4",
    },
}));

const TextFieldContainer = ({ label, value }) => {
    const classes = useStyles();

    return (
        <Box component="div" className={classes?.fieldContainer}>
            <Stack direction="column" spacing={3}>
                <Typography typography={"detailsLabel"}>{label}</Typography>
                <Typography typography={"detailsValue"}>
                    {value || "None"}
                </Typography>
            </Stack>
        </Box>
    );
};

const TextFieldInput = ({ label, value }) => (
    <TextField
        disabled
        fullWidth
        label={label}
        value={value}
        InputLabelProps={{
            style: { color: "#000000" },
        }}
        sx={{
            "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#000000",
            },
        }}
    />
);

const PatientDetailsForm = ({ patientDetails }) => {
    const classes = useStyles();

    return (
        <Box component="div" className={classes?.container}>
            <Grid container spacing={3} rowSpacing={4}>
                <Grid item md={6}>
                    <TextFieldInput
                        label={"First Name"}
                        value={patientDetails?.user?.details?.first_name || ""}
                    />
                </Grid>
                <Grid item md={6}>
                    <TextFieldInput
                        label="Last Name"
                        value={patientDetails?.user?.details?.last_name || ""}
                    />
                </Grid>
                <Grid item md={6}>
                    <TextFieldInput
                        label="Access Number"
                        value={
                            patientDetails?.user?.patient_details
                                ?.access_number || ""
                        }
                    />
                </Grid>
                <Grid item md={6}>
                    <TextFieldInput
                        label="Social Security Number"
                        value={
                            patientDetails?.user?.patient_details
                                ?.social_security_number || ""
                        }
                    />
                </Grid>
                <Grid item md={6}>
                    <TextFieldInput
                        label="Date of birth"
                        value={
                            patientDetails?.user?.patient_details
                                ?.date_of_birth || ""
                        }
                    />
                </Grid>
                <Grid item md={6}>
                    <TextFieldInput
                        label="Gender"
                        value={
                            patientDetails?.user?.patient_details?.gender || ""
                        }
                    />
                </Grid>
                <Grid item md={12}>
                    <Divider variant="middle" textAlign="left">
                        Care Home Details
                    </Divider>
                </Grid>
                <Grid item md={6}>
                    <TextFieldContainer
                        label={"Care Home"}
                        value={patientDetails?.carehome[0]?.name || ""}
                    />
                </Grid>
                <Grid item md={6}>
                    <TextFieldContainer
                        label={"Care Home Address"}
                        value={
                            patientDetails?.carehome[0]?.physical_address || ""
                        }
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

PatientDetailsForm.propTypes = {
    handleSubmit: PropTypes.func,
    initialValues: PropTypes.object,
};

export { PatientDetailsForm };
