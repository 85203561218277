import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    fetchInvitationsState: { invitations: null, isloading: "", errors: null },
    sendInvitationState: { invitation: null, isloading: "", errors: null },
    updateInvitationState: { invitation: null, isloading: "", errors: null },
    replyInvitationState: { invitation: null, isloading: "", errors: null },
};

export const invitationSlice = createSlice({
    name: "invitation",
    initialState,
    reducers: {
        fetchInvitations: (state) => {
            state.fetchInvitationsState.isloading = "processing";
            state.fetchInvitationsState.invitations = null;
            state.fetchInvitationsState.errors = null;
        },
        fetchInvitationsSuccess: (state, action) => {
            state.fetchInvitationsState.isloading = "success";
            state.fetchInvitationsState.invitations = action?.payload;
        },
        fetchInvitationsFailed: (state, action) => {
            state.fetchInvitationsState.isloading = "failed";
            state.fetchInvitationsState.errors = action?.payload;
        },
        sendInvitation: (state) => {
            state.sendInvitationState.isloading = "processing";
            state.sendInvitationState.errors = null;
        },
        sendInvitationSuccess: (state, action) => {
            state.sendInvitationState.isloading = "success";
            state.sendInvitationState.invitation = action?.payload;
        },
        sendInvitationFailed: (state, action) => {
            state.sendInvitationState.isloading = "failed";
            state.sendInvitationState.errors = action?.payload;
        },
        updateInvitation: (state) => {
            state.updateInvitationState.isloading = "processing";
            state.updateInvitationState.errors = null;
        },
        updateInvitationSuccess: (state, action) => {
            state.updateInvitationState.isloading = "success";
            state.updateInvitationState.invitation = action?.payload;
        },
        updateInvitationFailed: (state, action) => {
            state.updateInvitationState.isloading = "failed";
            state.updateInvitationState.errors = action?.payload;
        },
        replyInvitation: (state) => {
            state.replyInvitationState.isloading = "processing";
            state.replyInvitationState.errors = null;
        },
        replyInvitationSuccess: (state, action) => {
            state.replyInvitationState.isloading = "success";
            state.replyInvitationState.invitation = action?.payload;
        },
        replyInvitationFailed: (state, action) => {
            state.replyInvitationState.isloading = "failed";
            state.replyInvitationState.errors = action?.payload;
        },
        resetInvitation: (state) => {
            state.sendInvitationState.isloading = "";
            state.sendInvitationState.invitation = null;
            state.sendInvitationState.errors = null;
        },
    },
});

export const {
    fetchInvitations,
    fetchInvitationsSuccess,
    fetchInvitationsFailed,
    sendInvitation,
    sendInvitationSuccess,
    sendInvitationFailed,
    updateInvitation,
    updateInvitationSuccess,
    updateInvitationFailed,
    replyInvitation,
    replyInvitationSuccess,
    replyInvitationFailed,
    resetInvitation,
} = invitationSlice.actions;

export default invitationSlice.reducer;
