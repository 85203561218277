import { apiRequest } from "../../api/apiRequest";
import { WORKSPACE_ENDPOINT } from "../../api/urls";

export const fetchMembers = (id) => {
    return apiRequest("GET", WORKSPACE_ENDPOINT + "" + id + "/patients/");
};

export const addMembership = (workspace_id, data) => {
    return apiRequest(
        "POST",
        `${WORKSPACE_ENDPOINT}${workspace_id}/create/patient/`,
        data,
    );
};

export const updateMembership = (id, workspace_id, data) => {
    return apiRequest(
        "POST",
        `${WORKSPACE_ENDPOINT}${workspace_id}/update/patient/${id}/`,
        data,
    );
};

export const searchPatient = (access_number) => {
    return apiRequest(
        "GET",
        WORKSPACE_ENDPOINT + "patient/access_number/" + access_number + "/",
    );
};

export const attachPatient = (workspace_id, data) => {
    return apiRequest(
        "POST",
        `${WORKSPACE_ENDPOINT}${workspace_id}/attach/patient/`,
        data,
    );
};

export const removePatient = (workspace_id, data) => {
    return apiRequest(
        "POST",
        `${WORKSPACE_ENDPOINT}${workspace_id}/remove/patient/`,
        data,
    );
};
