import * as yup from "yup";

export const sortItems = (items, keyToSort) => {
    return [...items].sort((a, b) => {
        if (a[keyToSort] < b[keyToSort]) {
            return -1;
        }
        if (a[keyToSort] > b[keyToSort]) {
            return 1;
        }
        return 0;
    });
};

export const validationWorkspaceFormSchema = yup.object({
    name: yup.string().required("Title is required"),
    city: yup.string().required("City is required"),
    state: yup.string().required("State is required"),
    county: yup.string().required("County is required"),
    zip: yup.string(),
    telephone: yup.string().required("Telephone Number is required"),
    mailing_address: yup.string(),
    physical_address: yup.string().required("Physical Address is required"),
    website: yup.string(),
});

export const validationCreatePatientFormSchema = yup.object({
    first_name: yup.string().required("First Name is required"),
    last_name: yup.string().required("Last Name is required"),
    city: yup.string().required("City is required"),
    state: yup.string().required("State is required"),
    county: yup.string().required("County is required"),
    zip: yup.string(),
    telephone_work: yup.string().required("Telephone Number is required"),
    telephone_home: yup.string().required("Telephone Number is required"),
    area_code: yup.string().required("Area Code is required"),
    gender: yup.string().required("Gender is required"),
    date_of_birth: yup.string().required("Date of Birth is required"),
    street_address: yup.string(),
    social_security_number: yup
        .string()
        .required("Social Security Number is required"),
    access_number: yup.string().required("Access Number is required"),
});

export const validationLoginFormSchema = yup.object({
    email: yup.string().required("Email is required"),
    password: yup.string().required("Password is required"),
});

export const validationSignUpFormSchema = yup.object({
    last_name: yup.string().required("Last name is required"),
    first_name: yup.string().required("First name is required"),
    email: yup.string().required("Email is required"),
    // password: yup.string().required("Password is required"),
    // confirm_password: yup
    //     .string()
    //     .oneOf([yup.ref("password"), null], "Passwords must match"),
    password1: yup.string().required("Password is required"),
    password2: yup
        .string()
        .oneOf([yup.ref("password1"), null], "Passwords must match"),
});

export const openPage = (url) => {
    if (url) window.location.replace(url, "blank_");
};

export const validateEmails = (value) => {
    var validRegExp =
        /^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*(\+[_a-zA-Z0-9-]+)?@[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*(\.[A-Za-z]+)$/;
    let emails = [];
    if (value) emails = value.split(",");
    if (emails.length > 0) {
        let result = true;
        emails.forEach((v) => {
            let trimmedEmail = v.trim();
            if (!validRegExp.test(trimmedEmail)) {
                result = false;
            }
        });
        return result;
    } else {
        if (!validRegExp.test(value)) {
            return false;
        }
    }
    return true;
};

export const processResponseObject = (response) => {
    try {
        if (response && response.detail) {
            return response.detail;
        }

        let errorsArray = [];

        for (const [key, value] of Object.entries(response)) {
            if (key) {
                let _val = "";
                if (value && Array.isArray(value) && value.length > 0) {
                    value.forEach((message) => {
                        _val += message;
                    });
                } else {
                    _val = value;
                }
                errorsArray.push(_val);
            }
        }
        console.log("Run this errorsArray");
        return errorsArray;
    } catch (err) {
        return null;
    }
};

export const extractErrors = (response) => {
    try {
        if (Object.keys(response)) {
            if (Object.keys(response)[0] === "non_field_errors") {
                console.log("Run this non_field_errors");
                return response["non_field_errors"][0];
            }
        }
    } catch (err) {
        return null;
    }

    return null;
};
