import { call, put, takeLatest, takeLeading } from "redux-saga/effects";
import { extractErrors, processResponseObject } from "../../../util/helpers";
import {
    sendInvitation as sendInvitationApi,
    fetchInvitations as fetchInvitationsApi,
    updateInvitation as updateInvitationApi,
    replyInvitation as replyInvitationApi,
} from "./api";
import {
    sendInvitation,
    sendInvitationSuccess,
    sendInvitationFailed,
    updateInvitation,
    updateInvitationSuccess,
    updateInvitationFailed,
    replyInvitation,
    replyInvitationSuccess,
    replyInvitationFailed,
    fetchInvitations,
    fetchInvitationsSuccess,
    fetchInvitationsFailed,
} from "./slice";

export function* getInvitations(actions) {
    try {
        const slug = actions?.payload;
        const response = yield call(fetchInvitationsApi, slug);
        const resp = response && response.data;
        yield put(fetchInvitationsSuccess(resp));
    } catch (error) {
        const _message =
            extractErrors(e.response?.data) ||
            processResponseObject(e.response?.data);
        const message = _message || "Failed to fetch invitations";
        yield put(fetchInvitationsFailed(message));
    }
}

export function* inviteMembers(actions) {
    try {
        const { id, data } = actions?.payload;
        const response = yield call(sendInvitationApi, id, data);
        const resp = response && response.data;

        yield put(sendInvitationSuccess(resp));
    } catch (e) {
        const _message =
            extractErrors(e.response?.data) ||
            processResponseObject(e.response?.data);
        const message = _message || "Failed to invite members";
        yield put(sendInvitationFailed(message));
    }
}

export function* editInvitation(actions) {
    try {
        const { id, data } = actions?.payload;
        const response = yield call(updateInvitationApi, id, data);
        const resp = response && response.data;

        yield put(updateInvitationSuccess(resp));
    } catch (e) {
        const _message =
            extractErrors(e.response?.data) ||
            processResponseObject(e.response?.data);
        const message = _message || "Failed to update invitation";
        yield put(updateInvitationFailed(message));
    }
}

export function* acceptOrDeclineInvitation(actions) {
    try {
        const { id, data } = actions?.payload;
        const response = yield call(replyInvitationApi, id, data);
        const resp = response && response.data;

        yield put(replyInvitationSuccess(resp));
    } catch (e) {
        const _message =
            extractErrors(e.response?.data) ||
            processResponseObject(e.response?.data);
        const message = _message || "Failed to reply invitation";
        yield put(replyInvitationFailed(message));
    }
}

export function* watchFetchInvitations() {
    yield takeLatest(fetchInvitations.type, getInvitations);
}

export function* watchSendInvitation() {
    yield takeLeading(sendInvitation.type, inviteMembers);
}

export function* watchUpdateInvitation() {
    yield takeLeading(updateInvitation.type, editInvitation);
}

export function* watchReplyInvitation() {
    yield takeLeading(replyInvitation.type, acceptOrDeclineInvitation);
}
