import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Spinner from "../../../components/Spinner";
import { PatientDetailsForm } from "./PatientDetailsForm";
import { Typography } from "@mui/material";
import CustomModal from "../../CustomModal/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import {
    attachPatient,
    removePatient,
    resetAttachPatientState,
    resetRemovePatientState,
} from "../../../services/common/patients/slice";

const RenderErrors = ({ errors }) => {
    return <Typography typography={"title2"}>{errors}</Typography>;
};

const SearchModal = ({
    open,
    isloading,
    closeModal,
    patientDetails,
    accessNumber,
    activeWorkspace,
    errors,
}) => {
    const dispatch = useDispatch();

    const patientStates = useSelector((state) => state.patient);
    const { isloading: attachLoader } = patientStates?.attachPatientState;
    const { isloading: removeLoader } = patientStates?.removePatientState;

    useEffect(() => {
        if (attachLoader === "success" || removeLoader === "success") {
            closeModal();
        }

        return () => {
            dispatch(resetAttachPatientState());
            dispatch(resetRemovePatientState());
        };
    }, [attachLoader, removeLoader]);

    const hasCarehome = patientDetails?.carehome?.length === 1;
    const isSameWorkspace =
        patientDetails?.carehome[0]?.id === activeWorkspace?.id;
    const isCarehomeWorkspace = activeWorkspace?.workspace_type === "CareHome";
    const patientExists = patientDetails?.user !== undefined;

    return (
        <div>
            <CustomModal
                open={open}
                title={`Search Report for ${accessNumber}`}
                showActionButton={
                    (hasCarehome && isSameWorkspace && isCarehomeWorkspace) ||
                    (!hasCarehome && isCarehomeWorkspace && patientExists)
                }
                handleCloseModal={closeModal}
                handleSubmit={() => {
                    if (hasCarehome) {
                        if (isSameWorkspace) {
                            dispatch(
                                removePatient({
                                    workspace_id: activeWorkspace?.slug,
                                    data: { access_number: accessNumber },
                                }),
                            );
                        }
                    } else {
                        dispatch(
                            attachPatient({
                                workspace_id: activeWorkspace?.slug,
                                data: { access_number: accessNumber },
                            }),
                        );
                    }
                }}
                actionButtonText={
                    attachLoader === "processing" ||
                    removeLoader === "processing" ? (
                        <Spinner color="#fff" size={25} />
                    ) : hasCarehome && isSameWorkspace ? (
                        "Check Out"
                    ) : (
                        "Check In"
                    )
                }
            >
                {isloading === "processing" ? (
                    <Spinner size={20} />
                ) : errors ? (
                    <RenderErrors errors={errors} />
                ) : (
                    <PatientDetailsForm patientDetails={patientDetails} />
                )}
            </CustomModal>
        </div>
    );
};

SearchModal.propTypes = {
    open: PropTypes.bool.isRequired,
    isloading: PropTypes.string.isRequired,
    patientDetails: PropTypes.object,
    accessNumber: PropTypes.string,
    workspace_id: PropTypes.string,
    errors: PropTypes.string,
    closeModal: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
};

export { SearchModal };
