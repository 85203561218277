import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loginState: { isloading: "", errors: null },
    signUpState: { isloading: "", errors: null },
    fetchUserState: { user: null, isloading: "", errors: null },
};

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        fetchUser: (state) => {
            state.fetchUserState.isloading = "processing";
            state.fetchUserState.errors = null;
        },
        fetchUserSuccess: (state, action) => {
            state.fetchUserState.isloading = "success";
            state.fetchUserState.user = action?.payload;
        },
        fetchUserFailed: (state, action) => {
            state.fetchUserState.isloading = "failed";
            state.fetchUserState.errors = action?.payload;
        },
        loginRequest: (state) => {
            state.loginState.isloading = "processing";
            state.loginState.errors = null;
        },
        loginSuccess: (state) => {
            state.loginState.isloading = "success";
        },
        loginFailed: (state, action) => {
            state.loginState.isloading = "failed";
            state.loginState.errors = action?.payload;
        },
        signUpRequest: (state) => {
            state.signUpState.isloading = "processing";
            state.signUpState.errors = null;
        },
        signUpSuccess: (state) => {
            state.signUpState.isloading = "success";
        },
        signUpFailed: (state, action) => {
            state.signUpState.isloading = "failed";
            state.signUpState.errors = action?.payload;
        },
        logoutRequest: (state) => {
            state.fetchUserState.isloading = "";
            state.fetchUserState.user = null;
        },
        resetLogin: (state) => {
            state.loginState.isloading = "";
            state.loginState.errors = null;
        },
    },
});

export const {
    loginRequest,
    loginSuccess,
    loginFailed,
    signUpRequest,
    signUpSuccess,
    signUpFailed,
    logoutRequest,
    fetchUser,
    fetchUserSuccess,
    fetchUserFailed,
    resetLogin,
} = authSlice.actions;

export default authSlice.reducer;
