import React, { Suspense, useState, useEffect, useContext } from "react";
import { Router } from "react-router-dom";
import { history } from "./util/helpers/browserHistory";
import Spinner from "./components/Spinner";
import { PageHeader } from "./components/PageHeader";
import { PageContent } from "./components/PageContent";
import { SideBar } from "./components/Sidebar";
import { useDispatch, useSelector } from "react-redux";

import { fetchUser } from "./services/common/auth/slice";
import { AbilityContext } from "./util/helpers/Can";
import updateAbility from "./util/helpers/updateAbility";
import { AuthenticatedRoutes, UnauthenticatedRoutes } from "./routes";

const App = () => {
    const [transitioned, setTransitioned] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const ability = useContext(AbilityContext);
    const dispatch = useDispatch();

    const authStates = useSelector((state) => state.auth);
    const workspaceStates = useSelector((state) => {
        return state.workspaces;
    });
    const { isloading, user } = authStates?.fetchUserState;
    const { workspaces } = workspaceStates?.fetchWorkspacesState;
    const { permissions } = workspaceStates?.fetchWorkspacePermissionsState;

    useEffect(() => {
        dispatch(fetchUser());
    }, []);

    useEffect(() => {
        if (user?.user) {
            setIsLoggedIn(true);
        }
    }, [user]);

    useEffect(() => {
        // track transition of the loader
        if (isloading !== "processing") {
            setTransitioned(true);
        }
    }, [isloading]);

    useEffect(() => {
        updateAbility(ability, permissions);
    }, [permissions]);

    return (
        <>
            <Router history={history}>
                <Suspense fallback={<Spinner />}>
                    {isloading !== "processing" && transitioned ? (
                        <>
                            {isLoggedIn && <PageHeader />}
                            <div style={{ display: "flex" }}>
                                {isLoggedIn && workspaces.length > 0 ? (
                                    <SideBar />
                                ) : null}
                                <PageContent>
                                    <div>
                                        {isLoggedIn ? (
                                            <AuthenticatedRoutes />
                                        ) : (
                                            <UnauthenticatedRoutes
                                                isLoggedIn={isLoggedIn}
                                            />
                                        )}
                                    </div>
                                </PageContent>
                            </div>
                        </>
                    ) : (
                        <Spinner />
                    )}
                </Suspense>
            </Router>
        </>
    );
};

export default App;
