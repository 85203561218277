/* Base Urls */
export const BASE_URL = `${window._env_.API_HOST}/api`;

/* Auth endpoints */
export const AUTH_ENDPOINT = "/auth/";

/* User endpoints */
export const USER_ENDPOINT = "/user/";

/* Workspace */
export const WORKSPACE_ENDPOINT = "/workspace/";

/* Membership */
export const MEMBERSHIP_ENDPOINT = "/membership/";

/* Invitation */
export const INVITATION_ENDPOINT = "/invitation/";
