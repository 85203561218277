import { call, put, takeLatest, takeLeading } from "redux-saga/effects";
import { extractErrors, processResponseObject } from "../../../util/helpers";
import {
    fetchMembers as fetchMembersApi,
    updateMembership as updateMembershipApi,
    addMembership as addMembershipApi,
    searchPatient as searchPatientApi,
    attachPatient as attachPatientApi,
    removePatient as removePatientApi,
} from "./api";
import {
    addMembership,
    addMembershipSuccess,
    addMembershipFailed,
    updateMembership,
    updateMembershipSuccess,
    updateMembershipFailed,
    fetchMembers,
    fetchMembersSuccess,
    fetchMembersFailed,
    searchPatient,
    searchPatientSuccess,
    searchPatientFailed,
    attachPatient,
    attachPatientSuccess,
    attachPatientFailed,
    removePatient,
    removePatientSuccess,
    removePatientFailed,
} from "./slice";

export function* createMembership(actions) {
    try {
        const { workspace_id, data } = actions.payload;

        const response = yield call(addMembershipApi, workspace_id, data);
        const resp = response && response.data;

        yield put(addMembershipSuccess(resp));
    } catch (e) {
        const _message =
            extractErrors(e.response?.data) ||
            processResponseObject(e.response?.data);
        const message = _message || "Failed to update membership";
        yield put(addMembershipFailed(message));
    }
}

export function* editMembership(actions) {
    try {
        const { id, workspace_id, data } = actions.payload;

        const response = yield call(
            updateMembershipApi,
            id,
            workspace_id,
            data,
        );
        const resp = response && response.data;

        yield put(updateMembershipSuccess(resp));
    } catch (e) {
        const _message =
            extractErrors(e.response?.data) ||
            processResponseObject(e.response?.data);
        const message = _message || "Failed to update membership";
        yield put(updateMembershipFailed(message));
    }
}

export function* getMembers(actions) {
    try {
        const slug = actions?.payload;
        const response = yield call(fetchMembersApi, slug);
        const resp = response && response.data;
        yield put(fetchMembersSuccess(resp));
    } catch (error) {
        const _message =
            extractErrors(e.response?.data) ||
            processResponseObject(e.response?.data);
        const message = _message || "Failed to fetch members";
        yield put(fetchMembersFailed(message));
    }
}

export function* findPatient(actions) {
    try {
        const access_number = actions?.payload;
        const response = yield call(searchPatientApi, access_number);
        const resp = response && response.data;
        yield put(searchPatientSuccess(resp));
    } catch (error) {
        const _message =
            extractErrors(e.response?.data) ||
            processResponseObject(e.response?.data);
        const message = _message || "Could not patient with Access Number";
        yield put(searchPatientFailed(message));
    }
}

export function* attachMember(actions) {
    try {
        const { workspace_id, data } = actions.payload;

        const response = yield call(attachPatientApi, workspace_id, data);
        const resp = response && response.data;

        yield put(attachPatientSuccess(resp));
    } catch (e) {
        const _message =
            extractErrors(e.response?.data) ||
            processResponseObject(e.response?.data);
        const message = _message || "Failed to attach patient";
        yield put(attachPatientFailed(message));
    }
}

export function* removeMember(actions) {
    try {
        const { workspace_id, data } = actions.payload;

        const response = yield call(removePatientApi, workspace_id, data);
        const resp = response && response.data;

        yield put(removePatientSuccess(resp));
    } catch (e) {
        const _message =
            extractErrors(e.response?.data) ||
            processResponseObject(e.response?.data);
        const message = _message || "Failed to remove patient";
        yield put(removePatientFailed(message));
    }
}

export function* watchCreatePatient() {
    yield takeLeading(addMembership.type, createMembership);
}

export function* watchUpdatePatient() {
    yield takeLeading(updateMembership.type, editMembership);
}

export function* watchFetchPatients() {
    yield takeLatest(fetchMembers.type, getMembers);
}

export function* watchSearchPatient() {
    yield takeLatest(searchPatient.type, findPatient);
}

export function* watchAttachPatient() {
    yield takeLatest(attachPatient.type, attachMember);
}

export function* watchRemovePatient() {
    yield takeLatest(removePatient.type, removeMember);
}
