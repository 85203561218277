import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./store";
import { Provider } from "react-redux";

import { theme } from "./theme";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import { SnackbarProvider } from "notistack";
import ability from "./util/helpers/defineability";
import { AbilityContext } from "./util/helpers/Can";

ReactDOM.render(
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <SnackbarProvider maxSnack={3}>
                <CssBaseline />
                <AbilityContext.Provider value={ability}>
                    <App />
                </AbilityContext.Provider>
            </SnackbarProvider>
        </ThemeProvider>
    </Provider>,
    document.getElementById("root"),
);

reportWebVitals();
