import React from "react";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

export const CustomStyledButton = styled(Button)(
    ({ theme, width = "132px" }) => ({
        width: width,
        borderRadius: "2px !important",
        ...theme.buttongroup,
    }),
);

export const StyledButton = ({ ...props }) => {
    return <CustomStyledButton disableElevation {...props} />;
};
