import { Ability, AbilityBuilder } from "@casl/ability";

// update the permissions in the app depending on the returned permissions for the workspace
const updateAbility = (ability, permissions) => {
    const { can, rules } = new AbilityBuilder(Ability);

    // default for all workspace users
    can("view", "workspaces");
    can("create", "workspace");
    can("launch", "application");

    if (permissions?.length > 0) {
        permissions?.forEach((perm) => {
            let action = perm.slice(0, perm.indexOf("_"));
            let view = perm.slice(perm.indexOf("_") + 1);
            can(action, view);
        });
    }

    ability.update(rules);
};

export default updateAbility;
