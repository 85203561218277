import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { useDispatch, useSelector } from "react-redux";

import { Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { ProfileMenu } from "./components/ProfileMenu";
import SearchInput from "./components/SearchInput";
import { searchPatient } from "../../services/common/patients/slice";
import { SearchModal } from "./components/SearchModal";
import { logoutRequest } from "../../services/common/auth/slice";

const useStyles = makeStyles((theme) => ({
    logo: {
        height: "40px",
        paddingTop: "2px",
        marginTop: "8px",
    },
    logoWrapper: {
        flexGrow: 1,
        color: "#6590B9",
    },
    appBar: {
        background: "white",
        color: "black",
        zIndex: theme.zIndex.drawer + 1,
        boxShadow: "rgba(204, 204, 204, 0.5) 0px 2px 4px 0px",
    },
}));

export const PageHeader = () => {
    const classes = useStyles();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [open, setOpenModal] = useState(false);
    const [accessNumber, setAccessNumber] = useState("");

    const dispatch = useDispatch();

    const patientStates = useSelector((state) => state.patient);
    const { isloading, patient, errors } = patientStates?.searchPatientState;

    const authStates = useSelector((state) => state.auth);
    const { user } = authStates?.fetchUserState;

    const workspaceStates = useSelector((state) => {
        return state.workspaces;
    });
    const { active_workspace } = workspaceStates?.logintoWorkspaceState;
    const { workspaces } = workspaceStates?.fetchWorkspacesState;

    useEffect(() => {
        if (user) {
            setIsLoggedIn(true);
        } else setIsLoggedIn(false);
    }, [user]);

    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="fixed" className={classes.appBar}>
                    <Toolbar>
                        <Box component="div" className={classes.logoWrapper}>
                            {isLoggedIn && (
                                <SearchInput
                                    handleSubmit={(value) => {
                                        setAccessNumber(value);
                                        dispatch(searchPatient(value));
                                        setOpenModal(true);
                                    }}
                                />
                            )}
                        </Box>
                        {isLoggedIn && (
                            <>
                                <Typography typography={"title6"}>
                                    LoggedIn: {active_workspace?.name} (
                                    {active_workspace?.workspace_index_number})
                                </Typography>
                                <ProfileMenu
                                    user={user?.user}
                                    active_workspace={active_workspace}
                                    workspaces={workspaces}
                                    logout={() => {
                                        dispatch(logoutRequest());
                                    }}
                                />
                            </>
                        )}
                    </Toolbar>
                </AppBar>
            </Box>
            <SearchModal
                open={open}
                isloading={isloading}
                patientDetails={patient}
                accessNumber={accessNumber}
                activeWorkspace={active_workspace}
                errors={errors}
                handleSubmit={() => {}}
                closeModal={() => setOpenModal(false)}
            />
        </>
    );
};
