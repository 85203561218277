import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    uploadFileState: { data: null, isloading: "", errors: null },
    fetchWorkspaceFilesState: { files: [], isloading: "", errors: null },
};

export const filesSlice = createSlice({
    name: "files",
    initialState,
    reducers: {
        uploadFile: (state) => {
            state.uploadFileState.isloading = "processing";
            state.uploadFileState.errors = null;
        },
        uploadFileSuccess: (state) => {
            state.uploadFileState.isloading = "success";
        },
        uploadFileFailed: (state, action) => {
            state.uploadFileState.isloading = "failed";
            state.uploadFileState.errors = action?.payload;
        },
        fetchWorkspaceFiles: (state) => {
            state.fetchWorkspaceFilesState.isloading = "processing";
            state.fetchWorkspaceFilesState.errors = null;
        },
        fetchWorkspaceFilesSuccess: (state, action) => {
            state.fetchWorkspaceFilesState.isloading = "success";
            state.fetchWorkspaceFilesState.files = action?.payload;
        },
        fetchWorkspaceFilesFailed: (state, action) => {
            state.fetchWorkspaceFilesState.isloading = "failed";
            state.fetchWorkspaceFilesState.errors = action?.payload;
        },
        resetUploadFile: (state) => {
            state.uploadFileState.isloading = "";
            state.uploadFileState.errors = "";
            state.uploadFileState.data = null;
        },
    },
});

export const {
    uploadFile,
    uploadFileSuccess,
    uploadFileFailed,
    fetchWorkspaceFiles,
    fetchWorkspaceFilesSuccess,
    fetchWorkspaceFilesFailed,
    resetUploadFile,
} = filesSlice.actions;

export default filesSlice.reducer;
