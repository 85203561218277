import { call, put, takeLeading } from "redux-saga/effects";
import { extractErrors, processResponseObject } from "../../../util/helpers";
import {
    uploadFile as uploadFileApi,
    fetchWorkspaceFiles as fetchWorkspaceFilesApi,
} from "./api";
import {
    uploadFile,
    uploadFileSuccess,
    uploadFileFailed,
    fetchWorkspaceFiles,
    fetchWorkspaceFilesSuccess,
    fetchWorkspaceFilesFailed,
} from "./slice";

export function* sendFile(actions) {
    try {
        const { id, data, uploadProgress } = actions.payload;

        const response = yield call(uploadFileApi, id, data, uploadProgress);
        const resp = response && response.data;

        yield put(uploadFileSuccess(resp));
    } catch (e) {
        const _message =
            extractErrors(e.response?.data) ||
            processResponseObject(e.response?.data);
        const message = _message || "Failed to upload file";
        yield put(uploadFileFailed(message));
    }
}

export function* getFiles(actions) {
    try {
        const { workspace_id, searchTerm, status } = actions.payload;

        const response = yield call(
            fetchWorkspaceFilesApi,
            workspace_id,
            searchTerm,
            status,
        );
        const resp = response && response.data;

        yield put(fetchWorkspaceFilesSuccess(resp));
    } catch (e) {
        const _message =
            extractErrors(e.response?.data) ||
            processResponseObject(e.response?.data);
        const message = _message || "Failed to get workspace files";
        yield put(fetchWorkspaceFilesFailed(message));
    }
}

export function* watchuploadFile() {
    yield takeLeading(uploadFile.type, sendFile);
}

export function* watchfetchWorkspaceFiles() {
    yield takeLeading(fetchWorkspaceFiles.type, getFiles);
}
