import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import rootReducer from "../rootReducer";
import rootSaga from "../rootSaga";

// saga middleware
const sagaMiddleware = createSagaMiddleware();

// only apply logger middleware on development mode
const middleware = [...getDefaultMiddleware({ thunk: false }), sagaMiddleware];

// mount it on the Store
const store = configureStore({
    reducer: rootReducer,
    middleware,
});

sagaMiddleware.run(rootSaga);

export default store;
