import React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";

export const PageContent = ({ children }) => {
    return (
        <Container
            maxWidth="custom-lg"
            sx={{ mt: "100px", mb: "100px", ml: "20px", mr: "20px" }}
        >
            {children}
        </Container>
    );
};
