import { apiRequest } from "../../api/apiRequest";
import { WORKSPACE_ENDPOINT, INVITATION_ENDPOINT } from "../../api/urls";

export const fetchInvitations = (id) => {
    return apiRequest("GET", WORKSPACE_ENDPOINT + "" + id + "/invitations/");
};

export const sendInvitation = (id, data) => {
    return apiRequest("POST", `${WORKSPACE_ENDPOINT}${id}/invite/`, data);
};

export const updateInvitation = (id, data) => {
    return apiRequest("POST", `${INVITATION_ENDPOINT}${id}/update/`, data);
};

export const replyInvitation = (id, data) => {
    return apiRequest("POST", `${INVITATION_ENDPOINT}${id}/reply/`, data);
};
