import { all } from "redux-saga/effects";
import {
    watchFetchUser,
    watchLogin,
    watchLogout,
    watchSignUp,
} from "./services/common/auth/sagas";
import {
    watchFetchInvitations,
    watchReplyInvitation,
    watchSendInvitation,
    watchUpdateInvitation,
} from "./services/common/invitations/sagas";
import {
    watchFetchMembers,
    watchUpdateMembership,
} from "./services/common/membership/sagas";
import {
    watchAttachPatient,
    watchCreatePatient,
    watchFetchPatients,
    watchRemovePatient,
    watchSearchPatient,
    watchUpdatePatient,
} from "./services/common/patients/sagas";
import {
    watchAddWorkspace,
    watchDeleteWorkspace,
    watchFetchClinic,
    watchFetchClinics,
    watchfetchWorkspacePermissions,
    watchFetchWorkspaces,
    watchGetSingleWorkspace,
    watchUpdateWorkspace,
} from "./services/common/workspace/sagas";
import {
    watchfetchWorkspaceFiles,
    watchuploadFile,
} from "./services/common/files/sagas";

export default function* rootSaga() {
    yield all([
        watchLogout(),
        watchFetchUser(),
        watchLogin(),
        watchSignUp(),
        watchFetchWorkspaces(),
        watchfetchWorkspacePermissions(),
        watchAddWorkspace(),
        watchUpdateWorkspace(),
        watchGetSingleWorkspace(),
        watchDeleteWorkspace(),
        watchFetchMembers(),
        watchUpdateMembership(),
        watchSendInvitation(),
        watchFetchInvitations(),
        watchReplyInvitation(),
        watchUpdateInvitation(),
        watchFetchPatients(),
        watchCreatePatient(),
        watchUpdatePatient(),
        watchSearchPatient(),
        watchFetchClinics(),
        watchFetchClinic(),
        watchAttachPatient(),
        watchRemovePatient(),
        watchuploadFile(),
        watchfetchWorkspaceFiles(),
    ]);
}
