import * as React from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { PropTypes } from "prop-types";

const Spinner = ({ color="#54ADEC",  size = 40 }) => {
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <CircularProgress size={size} data-testid="loader" sx={{color: color}} />
        </Box>
    );
};

Spinner.propTypes = {
    size: PropTypes.number,
};

export default Spinner;
