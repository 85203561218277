import React from "react";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import { Stack, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { CloseOutlined } from "@mui/icons-material";
import { StyledButton } from "../StyledButton";

const useStyles = makeStyles({
    dialogPaper: {
        borderRadius: "12px !important",
    },
});

const CustomModal = ({
    open,
    title,
    children,
    actionButtonText,
    showActionButton,
    handleCloseModal,
    handleSubmit,
}) => {
    const classes = useStyles();
    return (
        <Dialog
            open={open}
            maxWidth={"lg"}
            fullWidth={true}
            onClose={handleCloseModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={classes?.container}
            PaperProps={{ classes: { root: classes?.dialogPaper } }}
        >
            <DialogTitle id="alert-dialog-title">
                <Stack direction="row" justifyContent="space-between">
                    <Stack direction="row" alignItems={"center"}>
                        <Typography typography={"title2"}>{title}</Typography>
                    </Stack>
                    <CloseOutlined
                        onClick={handleCloseModal}
                        fontSize="large"
                        sx={{ cursor: "pointer" }}
                    />
                </Stack>
            </DialogTitle>
            <DialogContent style={{ overflow: "hidden" }}>
                {children}
            </DialogContent>
            <DialogActions sx={{ padding: "8px 27px 15px" }}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems={"center"}
                    sx={{ width: "100%" }}
                >
                    <Typography
                        sx={{ color: "gray", cursor: "pointer" }}
                        onClick={handleCloseModal}
                    >
                        Cancel
                    </Typography>
                    {showActionButton && (
                        <StyledButton
                            variant="contained"
                            onClick={handleSubmit}
                        >
                            {actionButtonText}
                        </StyledButton>
                    )}
                </Stack>
            </DialogActions>
        </Dialog>
    );
};

CustomModal.propTypes = {
    open: PropTypes.bool,
    title: PropTypes.string,
    children: PropTypes.node,
    handleCloseModal: PropTypes.func,
};

export default CustomModal;
